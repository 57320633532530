import React, {Component} from 'react';
import styles from './Intro.module.css';
import  './Intro.css';
import ReactMarkdown from "react-markdown"
import breaks from "remark-breaks"
import {setCookie} from "../../services/service-cookies"
import Div100vh from 'react-div-100vh'
import LogoFloating from "../Logo/LogoFloating"




class Intro extends Component {
  constructor(props) 
  {
    super(props);

    this.redirectFinance = this.redirectFinance.bind(this);

    this.state = {
      introSelected: false 
    }


  }

  componentDidMount()
  {
    document.querySelector("body").classList.add("begin-animation");
  }


  redirectFinance(bool)
  {
    setCookie(this.props.globals.cookieFinanceString, bool, 365);
    window.location.href = "/soeg";
    
  }

  render()
  {
    const {title, intro} = this.props.data

    const {introSelected} = this.state

    console.log('Intro', this.props)

    return (
      <>
        {!introSelected && 
          <Div100vh className={styles.Intro}>

            <div className={styles.Tooltip}>
              <input id={styles.CheckboxIntro} type="checkbox" />
              <label className={styles.TooltipLabel + " tooltipLabel"} htmlFor={styles.CheckboxIntro}><span>i</span><span>X</span></label>
              <div className={styles.IntroText}>
                <div className={styles.IntroBubble}></div>
                <div className="container">
                  <div className="row">
                  <div className={styles.IntroTextBox}>
                    {title &&
                      <div className="columns col-xs-12 col-md-9">
                            <h1>{title}</h1>
                      </div>
                    }
                    {intro &&
                    <div className="columns col-xs-12 col-md-7 col-md-offset-5">
                      <div className="richtext">
                        <ReactMarkdown source={intro} plugins={[breaks]}/>
                      </div>
                    </div>
                    }
                  </div>
                  </div>
                 </div>
             </div>
              
     

       
            </div>

            <div className={styles.LeftContainer + " leftContainer"}>
              <div className="columns col-xs-12">
                <div className={styles.IntroButton + " no-select"} onClick={() => this.redirectFinance(false)}>
                  <div className={styles.ButtonText  + " h1"}><div>Digital</div><div>Dansk</div></div>
                  <div className={styles.Arrow}>❯❯</div>
                </div>
              </div>
            </div>
            <div className={styles.RightContainer + " rightContainer"}>
              <div className="columns col-xs-12">
                <div className={styles.IntroButton + " no-select"} onClick={() => this.redirectFinance(true)}>
                  <div className={styles.ButtonText + " h1"}><div>Finans</div><div>Dansk</div></div>
                  <div className={styles.ArrowRight}>❮❮</div>
                </div>
              </div>
            </div>
            <LogoFloating />        
          </Div100vh>
          
        }

        </>

        
    )
  }
}

export default Intro;
