import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Intro from "../components/Intro/Intro"
import {StateConsumer} from '../components/StateContext'


export class HomePageTemplate extends Component {
  render() {

    if(!this.props.data) return null

    return (
      <StateConsumer>{stateContext =>
        <>

          <Intro data={this.props.data} globals={stateContext} /> 
          </>
        }
      </StateConsumer>

    )
  }
}

HomePageTemplate.propTypes = {
  data: PropTypes.object
}

const HomePage = (props) => {
  const {frontmatter} = props.data.markdownRemark

  return (
    <HomePageTemplate data={frontmatter}/>
  )
}

HomePage.propTypes = {
  data: PropTypes.object
}

export default HomePage

export const pageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        title
        intro
      }
    }
  }
`
