import React, {Component} from 'react'
import styles from './Logo.module.css'
import logo from "../../assets/img/ffb_logo.svg"
import  './Logo.css';


class LogoFloating extends Component {


  render() {

    return (
      <div className={styles.Logo + " logo"}>
        <a href="https://www.finansforbundet.dk" target="_blank" rel="noopener noreferrer">    
        <img src={logo} alt="Finansforbundet logo" />
        </a> 
      </div>

    )
  }
}

export default LogoFloating
